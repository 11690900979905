import React from "react";
import {
  Box,
  Stack,
  Text,
  Button,
  VStack,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "components/Seo";
import Container from "components/ui/Container";
import * as styles from "styles/commons.module.scss";
import { Link } from "gatsby";

const TrainingCenterPage: React.FC = () => {
  return (
    <Box pb={10}>
      <Seo
        title="Experience The AiHouse Software"
        description="Join our training program to experience the AiHouse 3D interior design visualization and realistic renderings software for interior designers and furnishing brands with immersive experience. AiHouse is all-in-one interior and exterior design tools."
        keywords="interior design software, interior design rendering software, 3D interior and exterior design rendering software, online floor plan design software, cloud-based interior design and rendering software platform, online 4K rendering software"
      />

      <Container className="relative">
        <StaticImage
          src="../images/banners/training-center/header.jpg"
          alt="training center banner aihouse india"
          layout="fullWidth"
        />

        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          background="rgba(0, 0, 0, 0.3)"
          zIndex="4"
        />

        <Container
          height="full"
          width="full"
          display="flex"
          alignItems="center"
          fontWeight="bold"
          color="white"
          zIndex="5"
          position="absolute"
          top="0"
          left="0"
        >
          <Container fluid>
            <VStack
              spacing={{
                base: "2",
                md: "6",
              }}
              width="full"
              alignItems="start"
            >
              <Text
                fontWeight="bold"
                fontSize={{
                  base: "xl",
                  sm: "2xl",
                  md: "4xl",
                }}
                as="h2"
                lineHeight={{ base: "1.1", md: "1.2" }}
              >
                To experience The AiHouse Software
              </Text>
              <Text
                fontSize={{
                  base: "lg",
                  sm: "xl",
                  md: "2xl",
                }}
                fontWeight="light"
              >
                join our{" "}
                <Text
                  as="span"
                  backgroundColor="orange.500"
                  p={{ base: "1", md: "2" }}
                  rounded="sm"
                >
                  Training Program
                </Text>
              </Text>
            </VStack>
          </Container>
        </Container>
      </Container>

      <Container fluid mt="6">
        <Stack spacing={10} alignItems="center">
          <Stack spacing={6}>
            <Text as="h1" color="orange.500" textAlign="center" fontSize="4xl">
              Master 3D Design And Rendering Skills
            </Text>

            <Text>
              AiHouse is a user-friendly, intelligent, cloud-based 3D interior
              design visualization and rendering software platform where you can
              quickly create smart panorama and virtual reality (VR) to showcase
              to end-users/clients. With AiHouse 3D interior and exterior design
              rendering software, you can provide users with an immersive
              virtual reality environment to maximize design impact.
            </Text>

            <Text>
              With our online floor plan design software, whether you want to
              design floor plans, interior layouts, furniture, fixtures, and
              decorative elements, all that can now be done in a matter of
              minutes in an intuitive, seamless process. At the starting point,
              you have 4 ways to design entire complete floorplans &#8212;
              select from library, free drawing mode, importing/uploading from
              CAD software files & PNG/JPEG images.
            </Text>

            <Text>
              The Training Or Demo will conduct virtually via zoom. There is no
              obligation to purchase. We also offer the option of in-person
              training at our Gurgaon, HR office.
            </Text>

            <Flex
              justify="center"
              textAlign="center"
              maxW="md"
              alignSelf="center"
            >
              <Text fontSize="xl">
                To know more, we would like to invite you to our{" "}
                <Text as="span" color="orange.500" fontWeight="bold">
                  FREE DEMO or TRAINING
                </Text>{" "}
                sessions
              </Text>
            </Flex>

            <Grid
              templateColumns={{
                base: "repear(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              gap="6"
              maxW="4xl"
              alignSelf="center"
              textAlign="center"
            >
              <GridItem
                as={VStack}
                border="2px"
                borderColor="orange"
                rounded="md"
                p="6"
                spacing="6"
                shadow="md"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text
                  as="h5"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="xl"
                >
                  Demo session with Q&A
                </Text>
                <Text fontSize="lg">
                  Showcase design features, working tool-sets 3D modeling &
                  rendering.
                </Text>
              </GridItem>

              <GridItem
                as={VStack}
                border="2px"
                borderColor="orange"
                rounded="md"
                p="6"
                spacing="6"
                shadow="md"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Text
                  as="h5"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="xl"
                >
                  Two-Day Immersive Training
                </Text>
                <Text fontSize="lg">
                  Where you or your team can learn and practice in-depth on
                  using the software to design and render effortlessly.<br></br>
                  *for subscribers only!
                </Text>
              </GridItem>
            </Grid>

            <Box
              maxW="2xl"
              alignSelf="center"
              backgroundColor="orange.500"
              color="white"
              textAlign="center"
              p="2"
              rounded="sm"
              shadow="sm"
            >
              <Text>
                The Training Or Demo will conduct virtually via zoom. There is
                no obligation to purchase. We also offer the option of in-person
                training at our Gurgaon, HR office.
              </Text>
            </Box>
          </Stack>

          <Grid
            gap={6}
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
            }}
          >
            <Button
              colorScheme="orange"
              textTransform="uppercase"
              size="lg"
              w="full"
              as={Link}
              to="/contact-us/"
            >
              Contact us for free Demo
            </Button>

            <Button
              colorScheme="orange"
              variant="solid"
              size="lg"
              w="full"
              textTransform="uppercase"
              as="a"
              href="https://graph-new.aihouse.com/reg?owner=in"
              target="_blank"
              rel="noreferrer noopener"
            >
              Get my free trial
            </Button>
          </Grid>

          <StaticImage
            src="../images/banners/ai-house-package.png"
            alt="aihouse package image box"
          />

          <Button
            colorScheme="red"
            variant="outline"
            rightIcon={<DownloadIcon />}
            as={Link}
            to="/contact-us/?action=download_brochure"
            size="lg"
          >
            Download Brochure
          </Button>

          <Box className={styles.videoContainer} maxW="3xl">
            <iframe
              src="https://www.youtube.com/embed/KSxn8AxvNqU"
              title="AiHouse India"
              frameBorder="0"
              allowFullScreen={false}
            />
          </Box>

          <Text fontSize="2xl">
            For More Training Videos Follow{" "}
            <Text
              as="a"
              color="orange.600"
              textDecoration="underline"
              href="https://www.youtube.com/channel/UCECiZwLf3jHv2pvnJAQEfIw"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Our Youtube Channel
            </Text>
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};

export default TrainingCenterPage;
